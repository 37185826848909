
/* CSS for main page */

.Main
{
    text-align: center;
    font-size: 5em;
    padding-top: 0.75em;
}

.Main .row { padding-bottom: 0.5em;}

.Main .ctrlBtn { margin-left: 0.5em; margin-right: 0.5em; }

.Main .infoContainer { text-align: left; }