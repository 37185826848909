
/* CSS for homepage */

.Home
{ 
    font-size: 5em; 
    text-align: center;
    padding-top: 0.75em;
}

.Home .btn { margin: 0.5em; }